var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3fkWbkvf-eUE7rse7lqSV"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

const DEFAULT_DSN =
  'https://e36cbfc021aa46d3ae8e963dfc105d62@o169885.ingest.sentry.io/5993962';

Sentry.init({
  dsn:
    process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN || DEFAULT_DSN,
  environment: process.env.NEXT_PUBLIC_DEPLOY_ENV,
  integrations: [
    new BrowserTracing({
      beforeNavigate: (context) => {
        return {
          ...context,
          // Make dynamic paths generic.
          name: location.pathname
            .replace(/^\/promote\/[a-zA-Z0-9_-]+/, '/promote/:school')
            .replace(/^\/schools\/[a-zA-Z0-9_-]+/, '/schools/:school')
            .replace(
              /^\/schools\/[a-zA-Z0-9_-]+\/([a-zA-Z0-9_-]+)/,
              '/schools/:school/$1',
            ),
        };
      },
      tracingOrigins: [
        /^http:\/\/localhost/,
        /^https:\/\/cnaclassesforme\.com/,
      ],
    }),
  ],
  tracesSampler: () => 0.4,
});
